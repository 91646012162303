<template>
  <div>
    <div class="flex flex-row w-full">
      <div class="w-1/2">
        <p
          class="text-xl font-medium font-headers mb-3 font-bold text-grey-dark-2"
        >
          <span class="text-grey-medium-2 font-normal">
            Companies / {{ currentCompany.name }} /
          </span>
          Subscription and Invites
        </p>
      </div>
      <div class="flex w-1/2 justify-end">
        <Button
          text="Open as Company Admin"
          type="primary-white"
          size="medium"
          @click.native="doImpersonate"
          data-testid="impersonate-button"
        />
      </div>
    </div>
    <div class="space-y-2">
      <InvitesWarning v-if="subscriptionDisabled" :status="this.subscriptionInfo.subscriptionStatus" />
      <SubscriptionInfoCard :details="subscriptionInfo" :renewalDetails="subscriptionRenewalInfo" />
      <InvitesInfoCard :invitesInfo="invitesInfo" :allowUnlimitedInvites="allowUnlimitedInvites" />
      <RedeemedInvitesPerProduct :products="products" />
      <Licenses locatedIn="invites" :invitesInfo="invitesInfo" />
    </div>
  </div>
</template>

<script>
import RedeemedInvitesPerProduct from '../components/Licenses/RedeemedInvitesPerProduct.vue';
import InvitesInfoCard from '../components/InvitesInfoCard.vue';
import InvitesWarning from '../components/InvitesWarning/index.vue';
import SubscriptionInfoCard from '../components/SubscriptionInfoCard/index.vue';
import Licenses from '../components/Licenses/index';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'CompanyInvitesView',
  components: { RedeemedInvitesPerProduct, Licenses, InvitesInfoCard, InvitesWarning, SubscriptionInfoCard },
  methods: {
    ...mapActions({
      getOrgInvitesInfo: 'invites/getOrgInvitesInfo',
      getAllProductsInfo: 'invites/getAllProductsInfo',
      getSubscriptionDetails: 'subscriptions/getSubscriptionDetails',
      getSubscriptionRenewalDetails: "subscriptions/getSubscriptionRenewalDetails",
      impersonateAdmin: 'auth/impersonateAdmin'
    }),
    doImpersonate() {
      this.impersonateAdmin({ orgId: this.currentCompany.id });
    }
  },
  computed: {
    ...mapState({
      invitesInfo: (state) => state.invites.invitesInfo,
      allowUnlimitedInvites: state => state.invites.allowUnlimitedInvites,
      products: state => state.invites.products,
      subscriptionInfo: state => state.subscriptions.subscriptionDetails,
      subscriptionRenewalInfo: state => state.subscriptions.renewalDetails,
      currentCompany: state => state.companies.currentCompany
    }),
    subscriptionDisabled() {
      return this.subscriptionInfo && (this.subscriptionInfo.subscriptionStatus === 2 || this.subscriptionInfo.subscriptionStatus === 3 || this.subscriptionInfo.subscriptionStatus === 4)
    }
  },
  async mounted() {
    await this.getOrgInvitesInfo(this.$route.params.companyId);
    await this.getAllProductsInfo(this.$route.params.companyId);
    await this.getSubscriptionDetails(this.$route.params.companyId);
    await this.getSubscriptionRenewalDetails(this.$route.params.companyId);
  }
};
</script>
