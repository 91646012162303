var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-white rounded shadow px-2 pt-2 pb-3"},[_c('div',{staticClass:"flex justify-between items-center w-full"},[_c('p',{staticClass:"font-headers font-bold text-mdl text-grey-light"},[_vm._v(" Redeemed per Product ")]),_c('Button',{attrs:{"text":"Export","iconLeft":_vm.exportIcon,"type":"secondary","size":"medium"},nativeOn:{"click":function($event){return _vm.exportCsv.apply(null, arguments)}}})],1),_c('div',{staticClass:"mt-3 flex justify-end"},[_c('div',{staticClass:"flex justify-center items-center"},[_c('DatePicker',{attrs:{"mode":"date","max-date":_vm.end,"timezone":"UTC","data-testid":"start-date-select","model-config":{
          type: 'string',
          mask: 'iso',
          timeAdjust: '06:00:00',
        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var inputValue = ref.inputValue;
        var inputEvents = ref.inputEvents;
return [_c('div',[_c('p',{staticClass:"text-sm-2 text-grey-light font-medium mb-0.4 relative"},[_vm._v(" From ")]),_c('div',{staticClass:"flex"},[_c('input',_vm._g({staticClass:"w-full outline-none placeholder-grey-light-2 font-medium text-md text-grey-dark-1 px-2 py-1 border-grey-light-1 border rounded focus:border-blue-dark-1 disabled:text-grey-light disabled:placeholder-grey-light-1 disabled:border-grey-light-1 disabled:bg-white",domProps:{"value":inputValue}},inputEvents)),_c('img',{staticClass:"relative right-4",attrs:{"src":require("@/assets/img/icons/date.svg")}})])])]}}]),model:{value:(_vm.start),callback:function ($$v) {_vm.start=$$v},expression:"start"}}),_c('DatePicker',{attrs:{"mode":"date","min-date":_vm.start,"timezone":"UTC","data-testid":"end-date-select","model-config":{
          type: 'string',
          mask: 'iso',
          timeAdjust: '23:59:59',
        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var inputValue = ref.inputValue;
        var inputEvents = ref.inputEvents;
return [_c('div',[_c('p',{staticClass:"text-sm-2 text-grey-light font-medium mb-0.4 relative"},[_vm._v(" To ")]),_c('div',{staticClass:"flex"},[_c('input',_vm._g({staticClass:"w-full outline-none placeholder-grey-light-2 font-medium text-md text-grey-dark-1 px-2 py-1 border-grey-light-1 border rounded focus:border-blue-dark-1 disabled:text-grey-light disabled:placeholder-grey-light-1 disabled:border-grey-light-1 disabled:bg-white",domProps:{"value":inputValue}},inputEvents)),_c('img',{staticClass:"relative right-4",attrs:{"src":require("@/assets/img/icons/date.svg")}})])])]}}]),model:{value:(_vm.end),callback:function ($$v) {_vm.end=$$v},expression:"end"}})],1)]),_c('div',{staticClass:"mt-2"},[_c('div',{staticClass:"grid grid-cols-9 items-center bg-grey-light-3 py-1 px-4"},[_c('TableHeader',{staticClass:"col-span-7 place-self-start"},[_vm._v("PRODUCT NAME")]),_c('TableHeader',{staticClass:"col-span-2"},[_vm._v("INVITES REDEEMED")])],1),_vm._l((_vm.products),function(item,index){return _c('div',{key:index},[_c('div',{staticClass:"grid grid-cols-9 py-2 px-4"},[_c('p',{staticClass:"col-span-7 place-self-start text-md text-grey-dark-2"},[_vm._v(_vm._s(item.name))]),_c('p',{staticClass:"col-span-2 text-center font-semibold text-md text-grey-dark-1"},[_vm._v(_vm._s(item.redeemed))])]),_c('div',{staticClass:"w-full h-line bg-grey-light-4"})])})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }