<template>
  <div class="bg-white rounded shadow px-2 pt-2 pb-3">
    <div class="flex justify-between items-center w-full">
      <p class="font-headers font-bold text-mdl text-grey-light">
        Redeemed per Product
      </p>
      <Button
        text="Export"
        :iconLeft="exportIcon"
        type="secondary"
        size="medium"
        @click.native="exportCsv"
      />
    </div>
    <div class="mt-3 flex justify-end">
      <div class="flex justify-center items-center">
        <DatePicker
          v-model="start"
          mode="date"
          :max-date="end"
          timezone="UTC"
          data-testid="start-date-select"
          :model-config="{
            type: 'string',
            mask: 'iso',
            timeAdjust: '06:00:00',
          }"
        >
          <template v-slot="{ inputValue, inputEvents }">
            <div>
              <p class="text-sm-2 text-grey-light font-medium mb-0.4 relative">
                From
              </p>
              <div class="flex">
                <input
                  :value="inputValue"
                  v-on="inputEvents"
                  class="w-full outline-none placeholder-grey-light-2 font-medium text-md text-grey-dark-1 px-2 py-1 border-grey-light-1 border rounded focus:border-blue-dark-1 disabled:text-grey-light disabled:placeholder-grey-light-1 disabled:border-grey-light-1 disabled:bg-white"
                />
                <img
                  src="@/assets/img/icons/date.svg"
                  class="relative right-4"
                />
              </div>
            </div>
          </template>
        </DatePicker>
        <DatePicker
          v-model="end"
          mode="date"
          :min-date="start"
          timezone="UTC"
          data-testid="end-date-select"
          :model-config="{
            type: 'string',
            mask: 'iso',
            timeAdjust: '23:59:59',
          }"
        >
          <template v-slot="{ inputValue, inputEvents }">
            <div>
              <p class="text-sm-2 text-grey-light font-medium mb-0.4 relative">
                To
              </p>
              <div class="flex">
                <input
                  :value="inputValue"
                  v-on="inputEvents"
                  class="w-full outline-none placeholder-grey-light-2 font-medium text-md text-grey-dark-1 px-2 py-1 border-grey-light-1 border rounded focus:border-blue-dark-1 disabled:text-grey-light disabled:placeholder-grey-light-1 disabled:border-grey-light-1 disabled:bg-white"
                /><img
                  src="@/assets/img/icons/date.svg"
                  class="relative right-4"
                />
              </div>
            </div>
          </template>
        </DatePicker>
      </div>
    </div>
    <div class='mt-2'>
      <div
        class="grid grid-cols-9 items-center bg-grey-light-3 py-1 px-4"
      >
        <TableHeader class="col-span-7 place-self-start">PRODUCT NAME</TableHeader>
        <TableHeader class="col-span-2">INVITES REDEEMED</TableHeader>
      </div>
      <div v-for="(item, index) in products" :key="index" >
        <div class="grid grid-cols-9 py-2 px-4">
          <p class="col-span-7 place-self-start text-md text-grey-dark-2">{{ item.name }}</p>
          <p class="col-span-2 text-center font-semibold text-md text-grey-dark-1">{{ item.redeemed }}</p>
        </div>
        <div class="w-full h-line bg-grey-light-4" />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import exportIcon from '@/assets/img/icons/export.svg';
import Button from '../../../../components/Button/Button.vue';
import TableHeader from '@/components/Table/TableHeader.vue';
import { mapActions, mapState } from 'vuex';

Vue.component('date-picker', DatePicker);

export default {
  name: 'RedeemedInvitesPerProduct',
  components: { Button, DatePicker, TableHeader },
  props: {
    products: {
      type: Array,
      default: () => ({})
    }
  },
  data: () => ({
    exportIcon,
  }),
  computed: {
    ...mapState({
      range: state => state.invites.productsPayload
    }),
    orgId() {
      return parseInt(this.$route.params.companyId);
    },
    start: {
      get() {
        return this.range.startDate
      },
      set(val) {
        this.setProductsPayload({ field: 'startDate', value: val, orgId: this.orgId })
      },
    },
    end: {
      get() {
        return this.range.endDate
      },
      set(val) {
        this.setProductsPayload({ field: 'endDate', value: val, orgId: this.orgId })
      },
    }
  },
  methods: {
    ...mapActions({
      setProductsPayload: 'invites/setProductsPayload',
      exportProductsCsv: 'invites/exportProductsCsv'
    }),
    exportCsv() {
      this.exportProductsCsv(this.$papa);
    }
  },
};
</script>
