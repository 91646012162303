<template>
  <div class="rounded bg-blue-dark-1 flex flex-row px-2 py-1 space-x-1 items-center">
    <img :src="warning" />
    <p class="text-white text-sm">
      {{ message }}
    </p>
  </div>
</template>

<script>
import warning from '@/assets/img/warning-naked.svg';
export default {
  name: 'InvitesWarning',
  props: {
    status: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    warning,
  }),
  computed: {
    message() {
      switch (this.status) {
        case 3:
          return 'Invites cannot be redeemed by customers while subscription is suspended.';
        case 4:
          return 'Invites cannot be redeemed by customers while subscription is expired.';
        default:
          return 'Invites cannot be redeemed by customers while subscription is not activated.';
      }
    }
  }
};
</script>
